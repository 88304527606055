import React, { Component } from 'react'
import './App.css'
import { Route, Switch } from 'react-router-dom'
import Home from './Screens/Home'
import ScrollToTop from './Components/ScrollToTop'
import Header from './Components/Header'
import Menu from './Components/Menu'
import Footer from './Components/Footer'
import Page from './Screens/Page'
import Report from './Screens/Report'
import Navigation from './Components/Navigation'
import PageNews from './Screens/PageNews'
import moment from 'moment'

import 'react-responsive-modal/styles.css'
import Error from './Screens/Error'

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.state = {
      menu: false,
      scrolling: false,
      menustyle: false,
    }
  }

  openNavigation() {
    this.setState({ menu: !this.state.menu })
  }

  closeNavigation() {
    this.setState({ menu: false })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll(event) {
    console.log(window.pageYOffset)
    if (window.pageYOffset == 0) {
      this.setState({ scrolling: false })
    } else if (window.pageYOffset >= 1) {
      this.setState({ scrolling: true })
    }
  }

  changeMenuTrue() {
    this.setState({ menustyle: true })
  }

  changeMenuFalse() {
    this.setState({ menustyle: false })
  }

  render() {
    return (
      <div
        className="wrapper"
        ref={(elem) => {
          this.elem = elem
        }}
      >
      
        <Menu
          changeMenuTrue={this.changeMenuTrue.bind(this)}
          changeMenuFalse={this.changeMenuFalse.bind(this)}
          theme={
            this.state.menustyle == false &&
            this.state.scrolling == false &&
            this.state.menu == false
              ? 'dark_menu'
              : 'null'
          }
          openNavigation={this.openNavigation.bind(this)}
        />
        {this.state.menu == true ? (
          <Navigation
            changeMenuTrue={this.changeMenuTrue.bind(this)}
            changeMenuFalse={this.changeMenuFalse.bind(this)}
            closeNavigation={this.closeNavigation.bind(this)}
          />
        ) : null}

        <ScrollToTop>
          <Switch>
            <Route
              path="/page/:id"
              render={(match) => (
                <Page
                  changeMenuTrue={this.changeMenuTrue.bind(this)}
                  changeMenuFalse={this.changeMenuFalse.bind(this)}
                  {...match}
                />
              )}
            />
            <Route
              path="/pagenews/:id"
              render={(match) => (
                <PageNews
                  changeMenuTrue={this.changeMenuTrue.bind(this)}
                  changeMenuFalse={this.changeMenuFalse.bind(this)}
                  {...match}
                />
              )}
            />
            <Route
              path="/home"
              render={(match) => (
                <Home
                  changeMenuTrue={this.changeMenuTrue.bind(this)}
                  changeMenuFalse={this.changeMenuFalse.bind(this)}
                  {...match}
                />
              )}
            />
            <Route path="/report" render={(match) => <Report {...match} />} />

            <Route
              path="/"
              exact
              render={(match) => (
                <Home
                  changeMenuTrue={this.changeMenuTrue.bind(this)}
                  changeMenuFalse={this.changeMenuFalse.bind(this)}
                  {...match}
                />
              )}
            />

            <Route component={Error} />
          </Switch>
        </ScrollToTop>
        <Footer />
      </div>
    )
  }
}
