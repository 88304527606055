import moment from 'moment'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { getPageMenuID } from '../../API'
import PageLoader from '../../Sceleton/PageLoader'

export default class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.props.changeMenuTrue()
    getPageMenuID(this, this.props.match.params.id)
    setTimeout(() => this.setState({ loading: false }), 1200)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.setState({ loading: true })
      getPageMenuID(this, nextProps.match.params.id)
      setTimeout(() => this.setState({ loading: false }), 1200)
    }
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          {this.state.loading ? (
            <div style={{ height: 'calc(100vh - 250px)' }}>
              <PageLoader />
            </div>
          ) : (
            <div className="row">
              {this.state.page.length > 1 ? (
                this.state.page.map((item) => (
                  <div className="page_block">
                    <div className="page_category">{item.category_name}</div>
                    <div className="page_title">
                      {' '}
                      <NavLink to={'/pagenews/' + item.id}>
                        {' '}
                        {item.name}{' '}
                      </NavLink>
                    </div>
                    <div className="page_content">
                      <span className="m0">{item.title}</span>

                      <div className="page_date">
                        {moment(item.date, 'YYYY-MM-DD').format(
                          'MMMM DD, YYYY',
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : this.state.page.length === 1 ? (
                this.state.page.map((item) => (
                  <div>
                    <div className="page_category">{item.category_name}</div>
                    <div className="page_title">{item.name}</div>
                    <div className="page_content">
                      {item.image == null ? null : <img src={item.image} />}
                      <span>{item.title}</span>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        ></div>
                      </p>
                      <div className="page_date">
                        {moment(item.date, 'YYYY-MM-DD').format(
                          'MMMM DD, YYYY',
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: 'calc(100vh - 250px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <h3 style={{ opacity: 0.7, fontWeight: 400 }}>Havola yo'q</h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
