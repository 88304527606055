import axios from 'axios'

const API = 'https://azizayoladmin.academytable.ru/api/v1/'

export async function getMenu(component) {
  axios
    .get('https://azizayoladmin.academytable.ru/api/v1/menu/')
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ menu: data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getAllPages(component) {
  axios
    .get('https://azizayoladmin.academytable.ru/api/v1/pageall/?ordering=-id')
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ pages: data })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getPageID(component, id) {
  axios
    .get(`https://azizayoladmin.academytable.ru/api/v1/pageid/${id}/`)
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ page: data[0] })
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getPageMenuID(component, id) {
  axios
    .get(`https://azizayoladmin.academytable.ru/api/v1/page/${id}/`)
    .then((response) => response.data)
    .then((data) => {
      console.log(data)
      component.setState({ page: data })
    })
    .catch((error) => {
      console.log(error)
    })
}
