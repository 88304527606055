import React, { Component } from 'react'
import { IoMail, IoPaperPlane } from 'react-icons/io5'

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className={'header '} id={this.props.theme}>
        <div className="container">
          <div className="row">
            <div className="header_left">
              <p>
                Ishonch telefoni:{' '}
                <span>
                  <a href="tel:1146">1146</a>
                </span>
              </p>
            </div>
            <div className="header_right">
              <div className="header_right_lang">
                <ul>
                  <li>
                    <a className="active">O`zbekcha</a>
                  </li>
                  <li>
                    <a onClick={() => alert('недоступен')}>Русский</a>
                  </li>
                  <li>
                    <a onClick={() => alert('mavjud emas')}>Узбекча</a>
                  </li>
                </ul>
              </div>
              <div className="header_right_info">
                <ul>
                  <li className="header_right_info_mail">
                    <IoMail size={14} />
                    <a href="mailto: murojaat@aziz-ayol.uz">
                      murojaat@aziz-ayol.uz
                    </a>
                  </li>
                  <li className="header_right_info_location">
                    <IoPaperPlane size={14} /> Toshkent
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
